.dropdownItem{
    text-align: left;
    margin: 0.25em 0 1.5em 1.5em;

    .header{
        display: flex;
        align-content: center;
        color: var(--bs-gray-700);
        text-align: left;
        margin: 0.75em 0 1em 0;
    }

    .mediaType{
        text-align: left;
        padding: 0.25em 0.5em 0.25em 0.75em;
        margin: 0;
        font-weight: bold;
    }

    .client{
        text-align: left;
        padding: 0.25em 0.5em 0.25em 1.75em;
        cursor: pointer;
        border-radius: 0.475rem !important;
        margin: 0em 0;

        &:hover:not(.selected){
            background-color: var(--bs-gray-200);
        }
        &.selected{
            color: var(--bs-primary);
            &::before{
                // position:absolute;
                color: var(--bs-primary);
                left:1.5em;
                font-size: 1.35em;
                display: inline-block;
                content: "✓";
                border: none;
                margin: 0 0.435em 0 -1em;
            }
        }
    }
}