$--bs-btn-disabled-opacity: 0.65;
.DropdownMenu{
    .custom-dropdown-menu {
        position: absolute;
        top: 0;
        left: 0;
        transform: none !important;
    }
    .dropdown-menu.show {
        animation: none !important;
      }
}
// 선택자 우선순위를 가저가기 위한 선택사항
.toggle[id=Dropdown_Toggle_Custom]:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush):not(.btn-icon){
    &::after{
        display: none;
    } 
    color: var(--bs-dark) !important;
    background-color: var(--bs-light);
    border-color: var(--bs-gray-300) !important;
    &:after{color: var(--bs-dark) !important;}
    & *::before{color: var(--bs-dark) !important;}

    &[data-type=icon]{
        padding: calc(0.55rem + 1px);
        min-width: 35px !important;
    }

    box-shadow: none;
    cursor: pointer;
    white-space: nowrap;

    padding: calc(0.55rem + 1px) calc(1.25rem + 1px);
    padding-top: 0;
    padding-bottom: 0;
    margin: 0;
    border-radius: 0.475rem;
    outline: none !important;
    height: 35px !important;
    font-size: 1rem !important;

    &:focus, &:active{
        background-color: var(--bs-light) !important;
        border-color: var(--bs-gray-300) !important;
    }
    &:hover{
        background-color: var(--bs-primary-light) !important;
        color: var(--bs-primary) !important;
        border-color: var(--bs-primary) !important;
        & *:not([data-fixed=true]){color: var(--bs-primary) !important;}
        &:after{color: var(--bs-primary) !important;}
        & *:not([data-fixed=true])::before{color: var(--bs-primary) !important;}
    }

    &:disabled{
        cursor: default;
        color: var(--bs-dark) !important;
        background-color: var(--bs-light) !important;
        border-color: var(--bs-gray-300) !important;
        opacity: $--bs-btn-disabled-opacity;
        & *:not([data-fixed=true]){color: var(--bs-dark) !important;}
        &:after{color: var(--bs-dark) !important;}
        & *:not([data-fixed=true])::before{color: var(--bs-dark) !important; opacity: $--bs-btn-disabled-opacity;}
    }
}