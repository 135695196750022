.qlist{
    .title{
        color: var(--bs-gray-600);
    }
    .list{
        padding-left: 0;
        .item{
            i{
                color: var(--bs-text);
                font-size: 1.2em;
            }
            .icon{
                display: inline-block;
                margin-right: 0.5em;
            }
            .text{
                display: inline-block;
            }

            .arrow{
                display: none;
                right: 1.75em;
                position: absolute;
                padding-top: 0.125em;
            }
            border-radius: 0.75em;
            padding: 0.725em;
            cursor: pointer;
            
            &:hover{
                background-color: var(--bs-gray-300);
                .arrow{
                    display:inline;
                }
            }
        }
    }
}


.UserMessage{
    width: 100%;
    justify-items: end;

    & > div {
        color: var(--bs-white);
        width: fit-content !important;
        background-color: var(--bs-info);
        border-top-left-radius: 1em;
        border-top-right-radius: 1em;
        border-bottom-left-radius: 1em;
    }
}

.BotMessageWait{

    & > div table {
        width: 100%;
        margin: 1em 0;
        & tr > th, & tr > td{
            padding: 0.5em 1em;
            border: 1px solid var(--bs-gray-600);
        }
        & tr > th {
            background-color: var(--bs-gray-200);
        }
    }

    & > div > ul > li {
        margin: 1em 0;
    }

    & .Content img{
        width: 100%;
        margin: 1em 0;
        display: block;
    }

    
}
