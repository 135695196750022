.link{
    font-weight:bold;
    cursor: pointer;
    color: var(--bs-primary-active);
    &:hover{
        text-decoration: underline;;
    }
}
.hiperLink{
    cursor: pointer;
    color: var(--bs-primary-active);
    &:hover{
        text-decoration: underline;;
    }
}