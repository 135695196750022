.input_box{
    font-size: 1rem !important;
    align-items: center;
    & input[type=number]{
        text-align:right;
    }
    & input{
        font-size: 1rem !important;
        color: var(--bs-dark);
        &::placeholder{
            color: var(--bs-gray-600);
        }
        &:focus,&:hover{
            border-color: var(--bs-primary);
        }
    }
    & label{
        padding: 0 0.5em 0 0.25em !important;
        margin:0 !important;
     }
}

.notice{
    margin: 0 0 0 1.5em;
    p{
        padding: 0;
    }
}

.link_label{
    &:hover{
        cursor: pointer;
        color: var(--bs-primary);
        text-decoration: underline;
        & > i{
            color: var(--bs-primary);
        }
    }
}