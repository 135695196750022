.loading{
    background-color: rgba(30,30,30,0.5);
    z-index: 9999999;
}

.loading_dialog {
    border: 0px;
    z-index: -1;
    content: none;
    height: 100vh;
    
    & > div.modal-content {
        border: 0px;
    }
}


.loading_container {
    border: 0px;
    height: 100vh;
    width: 100%;
    background-color: rgba(30,30,30,0.5);
}

.overlay_box {
    position: fixed;
    justify-content: center !important;
    top: 50%;
    left: 50%;
    color: white;
    background: rgba(255, 255, 255, 0);
    z-index: 1000;
}

