$primary: red;
$white: white;

.trFocus {
  border: none;
  height: 2rem;
  width: 2rem;
  margin: 0 0.25rem;
  // font-weight: 600;
  &:hover {
      // text-decoration: underline;
  }
  &:focus {
      outline: 0;
  }
}
.active {
  border: 1.2px dashed;
  border-color: $primary;
}

.columnlist_item{
  &:hover{
      color: var(--bs-dropdown-link-hover-color);
      background-color: var(--bs-dropdown-link-hover-bg);
  }

}

input[type=checkbox] {
  margin: 0;
  margin-right: 5px;
  font-size: 0.95rem;
  font-weight: 500;
  line-height: 1.5;
  color: var(--bs-gray-700);
  width: 1.55rem;
  height: 1.55rem;
  background-color: var(--bs-gray-300);
  background-image: var(--bs-form-check-bg-image);
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 0.45em;
  appearance: none;
}

input:checked[type=checkbox] {
  background-color: #009ef7;
  --bs-form-check-bg-image: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 13 11%27 width=%2713%27 height=%2711%27 fill=%27none%27%3e%3cpath d=%27M11.0426 1.02893C11.3258 0.695792 11.8254 0.655283 12.1585 0.938451C12.4917 1.22162 12.5322 1.72124 12.249 2.05437L5.51985 9.97104C5.23224 10.3094 4.72261 10.3451 4.3907 10.05L0.828197 6.88335C0.50141 6.59288 0.471975 6.09249 0.762452 5.7657C1.05293 5.43891 1.55332 5.40948 1.88011 5.69995L4.83765 8.32889L11.0426 1.02893Z%27 fill=%27%23ffffff%27/%3e%3c/svg%3e);
}

input[type="date"]::-webkit-calendar-picker-indicator { 
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='%237E8299' class='bi bi-calendar-event' viewBox='0 0 16 16'%3E%3Cpath d='M11 6.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1z'/%3E%3Cpath d='M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z'/%3E%3C/svg%3E");
  opacity: 0.7;
}
