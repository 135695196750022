
/* table.custom_table thead.grouptype tr th{ padding:0.35rem; } */
table.custom_table thead tr.custom-table-title th{
    /* padding-left:0;
    padding-right:0; */
    text-align: left;
    white-space: nowrap;
}

table.custom_table th, table.custom_table tfoot td {
    padding: 0.75rem 0.3rem;
    /* text-align: center; */
}

table.custom_table tr > th.th_group {
    padding: 0.43rem 0.3rem !important;
    /* text-align: center; */
}

table.custom_table thead tr:nth-child(-n+2) th{
    background-color: var(--bs-gray-100);
}
table.custom_table thead tr:nth-child(n+3) th{
    background-color: var(--bs-body-bg);
}
table.custom_table tbody tr td{
    background-color: var(--bs-gray-100);
}
table.custom_table tbody tr:nth-child(even) td{
    background-color: var(--bs-body-bg);
}

table.custom_table .sort-by-asc
{
    left: 3px;
    display: inline-block;
    width: 0;
    height: 0;
    border: solid 5px transparent;
    margin: 4px 4px 0 3px;
    background: transparent;
    border-bottom: solid 7px rgb(95, 224, 78);
    border-top-width: 0;
}

table.custom_table .sort-by-desc
{
    left: 3px;
    display: inline-block;
    width: 0;
    height: 0;
    border: solid 5px transparent;
    margin: 4px 4px 0 3px;
    background: transparent;
    border-top: solid 7px rgb(95, 224, 78);
    border-bottom-width: 0;
}
table.custom_table tbody tr td, table.custom_table thead tr th{ max-width:50rem; }

.width_inherit {
    max-width:"unset";
    max-width: 19000px;
}

table.custom_table[data-checkbox=true]{ --fixed-column-c1-width: 30px; }
table.custom_table.w-c1-10px{ --fixed-column-c1-width: 10px; }
table.custom_table.w-c1-20px{ --fixed-column-c1-width: 20px; }
table.custom_table.w-c1-30px{ --fixed-column-c1-width: 30px; }
table.custom_table.w-c1-40px{ --fixed-column-c1-width: 40px; }
table.custom_table.w-c1-50px{ --fixed-column-c1-width: 50px; }
table.custom_table.w-c1-60px{ --fixed-column-c1-width: 60px; }
table.custom_table.w-c1-70px{ --fixed-column-c1-width: 70px; }
table.custom_table.w-c1-80px{ --fixed-column-c1-width: 80px; }
table.custom_table.w-c1-90px{ --fixed-column-c1-width: 90px; }
table.custom_table.w-c1-100px{ --fixed-column-c1-width: 100px; }
table.custom_table.w-c1-110px{ --fixed-column-c1-width: 110px; }
table.custom_table.w-c1-120px{ --fixed-column-c1-width: 120px; }
table.custom_table.w-c1-130px{ --fixed-column-c1-width: 130px; }
table.custom_table.w-c1-140px{ --fixed-column-c1-width: 140px; }
table.custom_table.w-c1-150px{ --fixed-column-c1-width: 150px; }
table.custom_table.w-c1-160px{ --fixed-column-c1-width: 160px; }
table.custom_table.w-c1-170px{ --fixed-column-c1-width: 170px; }
table.custom_table.w-c1-180px{ --fixed-column-c1-width: 180px; }
table.custom_table.w-c1-190px{ --fixed-column-c1-width: 190px; }
table.custom_table.w-c1-200px{ --fixed-column-c1-width: 200px; }
table.custom_table.w-c1-210px{ --fixed-column-c1-width: 210px; }
table.custom_table.w-c1-220px{ --fixed-column-c1-width: 220px; }
table.custom_table.w-c1-230px{ --fixed-column-c1-width: 230px; }
table.custom_table.w-c1-240px{ --fixed-column-c1-width: 240px; }
table.custom_table.w-c1-250px{ --fixed-column-c1-width: 250px; }
table.custom_table.w-c1-260px{ --fixed-column-c1-width: 260px; }
table.custom_table.w-c1-270px{ --fixed-column-c1-width: 270px; }
table.custom_table.w-c1-280px{ --fixed-column-c1-width: 280px; }
table.custom_table.w-c1-290px{ --fixed-column-c1-width: 290px; }
table.custom_table.w-c1-300px{ --fixed-column-c1-width: 300px; }
table.custom_table.w-c1-310px{ --fixed-column-c1-width: 310px; }
table.custom_table.w-c1-320px{ --fixed-column-c1-width: 320px; }
table.custom_table.w-c1-330px{ --fixed-column-c1-width: 330px; }
table.custom_table.w-c1-340px{ --fixed-column-c1-width: 340px; }
table.custom_table.w-c1-350px{ --fixed-column-c1-width: 350px; }
table.custom_table.w-c1-360px{ --fixed-column-c1-width: 360px; }
table.custom_table.w-c1-370px{ --fixed-column-c1-width: 370px; }
table.custom_table.w-c1-380px{ --fixed-column-c1-width: 380px; }
table.custom_table.w-c1-390px{ --fixed-column-c1-width: 390px; }
table.custom_table.w-c1-400px{ --fixed-column-c1-width: 400px; }

table.custom_table.w-c2-10px{ --fixed-column-c2-width: 10px; }
table.custom_table.w-c2-20px{ --fixed-column-c2-width: 20px; }
table.custom_table.w-c2-30px{ --fixed-column-c2-width: 30px; }
table.custom_table.w-c2-40px{ --fixed-column-c2-width: 40px; }
table.custom_table.w-c2-50px{ --fixed-column-c2-width: 50px; }
table.custom_table.w-c2-60px{ --fixed-column-c2-width: 60px; }
table.custom_table.w-c2-70px{ --fixed-column-c2-width: 70px; }
table.custom_table.w-c2-80px{ --fixed-column-c2-width: 80px; }
table.custom_table.w-c2-90px{ --fixed-column-c2-width: 90px; }
table.custom_table.w-c2-100px{ --fixed-column-c2-width: 100px; }
table.custom_table.w-c2-110px{ --fixed-column-c2-width: 110px; }
table.custom_table.w-c2-120px{ --fixed-column-c2-width: 120px; }
table.custom_table.w-c2-130px{ --fixed-column-c2-width: 130px; }
table.custom_table.w-c2-140px{ --fixed-column-c2-width: 140px; }
table.custom_table.w-c2-150px{ --fixed-column-c2-width: 150px; }
table.custom_table.w-c2-160px{ --fixed-column-c2-width: 160px; }
table.custom_table.w-c2-170px{ --fixed-column-c2-width: 170px; }
table.custom_table.w-c2-180px{ --fixed-column-c2-width: 180px; }
table.custom_table.w-c2-190px{ --fixed-column-c2-width: 190px; }
table.custom_table.w-c2-200px{ --fixed-column-c2-width: 200px; }
table.custom_table.w-c2-210px{ --fixed-column-c2-width: 210px; }
table.custom_table.w-c2-220px{ --fixed-column-c2-width: 220px; }
table.custom_table.w-c2-230px{ --fixed-column-c2-width: 230px; }
table.custom_table.w-c2-240px{ --fixed-column-c2-width: 240px; }
table.custom_table.w-c2-250px{ --fixed-column-c2-width: 250px; }
table.custom_table.w-c2-260px{ --fixed-column-c2-width: 260px; }
table.custom_table.w-c2-270px{ --fixed-column-c2-width: 270px; }
table.custom_table.w-c2-280px{ --fixed-column-c2-width: 280px; }
table.custom_table.w-c2-290px{ --fixed-column-c2-width: 290px; }
table.custom_table.w-c2-300px{ --fixed-column-c2-width: 300px; }
table.custom_table.w-c2-310px{ --fixed-column-c2-width: 310px; }
table.custom_table.w-c2-320px{ --fixed-column-c2-width: 320px; }
table.custom_table.w-c2-330px{ --fixed-column-c2-width: 330px; }
table.custom_table.w-c2-340px{ --fixed-column-c2-width: 340px; }
table.custom_table.w-c2-350px{ --fixed-column-c2-width: 350px; }
table.custom_table.w-c2-360px{ --fixed-column-c2-width: 360px; }
table.custom_table.w-c2-370px{ --fixed-column-c2-width: 370px; }
table.custom_table.w-c2-380px{ --fixed-column-c2-width: 380px; }
table.custom_table.w-c2-390px{ --fixed-column-c2-width: 390px; }
table.custom_table.w-c2-400px{ --fixed-column-c2-width: 400px; }

table.custom_table.w-c3-10px{ --fixed-column-c3-width: 10px; }
table.custom_table.w-c3-20px{ --fixed-column-c3-width: 20px; }
table.custom_table.w-c3-30px{ --fixed-column-c3-width: 30px; }
table.custom_table.w-c3-40px{ --fixed-column-c3-width: 40px; }
table.custom_table.w-c3-50px{ --fixed-column-c3-width: 50px; }
table.custom_table.w-c3-60px{ --fixed-column-c3-width: 60px; }
table.custom_table.w-c3-70px{ --fixed-column-c3-width: 70px; }
table.custom_table.w-c3-80px{ --fixed-column-c3-width: 80px; }
table.custom_table.w-c3-90px{ --fixed-column-c3-width: 90px; }
table.custom_table.w-c3-100px{ --fixed-column-c3-width: 100px; }
table.custom_table.w-c3-110px{ --fixed-column-c3-width: 110px; }
table.custom_table.w-c3-120px{ --fixed-column-c3-width: 120px; }
table.custom_table.w-c3-130px{ --fixed-column-c3-width: 130px; }
table.custom_table.w-c3-140px{ --fixed-column-c3-width: 140px; }
table.custom_table.w-c3-150px{ --fixed-column-c3-width: 150px; }
table.custom_table.w-c3-160px{ --fixed-column-c3-width: 160px; }
table.custom_table.w-c3-170px{ --fixed-column-c3-width: 170px; }
table.custom_table.w-c3-180px{ --fixed-column-c3-width: 180px; }
table.custom_table.w-c3-190px{ --fixed-column-c3-width: 190px; }
table.custom_table.w-c3-200px{ --fixed-column-c3-width: 200px; }
table.custom_table.w-c3-210px{ --fixed-column-c3-width: 210px; }
table.custom_table.w-c3-220px{ --fixed-column-c3-width: 220px; }
table.custom_table.w-c3-230px{ --fixed-column-c3-width: 230px; }
table.custom_table.w-c3-240px{ --fixed-column-c3-width: 240px; }
table.custom_table.w-c3-250px{ --fixed-column-c3-width: 250px; }
table.custom_table.w-c3-260px{ --fixed-column-c3-width: 260px; }
table.custom_table.w-c3-270px{ --fixed-column-c3-width: 270px; }
table.custom_table.w-c3-280px{ --fixed-column-c3-width: 280px; }
table.custom_table.w-c3-290px{ --fixed-column-c3-width: 290px; }
table.custom_table.w-c3-300px{ --fixed-column-c3-width: 300px; }
table.custom_table.w-c3-310px{ --fixed-column-c3-width: 310px; }
table.custom_table.w-c3-320px{ --fixed-column-c3-width: 320px; }
table.custom_table.w-c3-330px{ --fixed-column-c3-width: 330px; }
table.custom_table.w-c3-340px{ --fixed-column-c3-width: 340px; }
table.custom_table.w-c3-350px{ --fixed-column-c3-width: 350px; }
table.custom_table.w-c3-360px{ --fixed-column-c3-width: 360px; }
table.custom_table.w-c3-370px{ --fixed-column-c3-width: 370px; }
table.custom_table.w-c3-380px{ --fixed-column-c3-width: 380px; }
table.custom_table.w-c3-390px{ --fixed-column-c3-width: 390px; }
table.custom_table.w-c3-400px{ --fixed-column-c3-width: 400px; }

table.custom_table.w-c4-10px{ --fixed-column-c4-width: 10px; }
table.custom_table.w-c4-20px{ --fixed-column-c4-width: 20px; }
table.custom_table.w-c4-30px{ --fixed-column-c4-width: 30px; }
table.custom_table.w-c4-40px{ --fixed-column-c4-width: 40px; }
table.custom_table.w-c4-50px{ --fixed-column-c4-width: 50px; }
table.custom_table.w-c4-60px{ --fixed-column-c4-width: 60px; }
table.custom_table.w-c4-70px{ --fixed-column-c4-width: 70px; }
table.custom_table.w-c4-80px{ --fixed-column-c4-width: 80px; }
table.custom_table.w-c4-90px{ --fixed-column-c4-width: 90px; }
table.custom_table.w-c4-100px{ --fixed-column-c4-width: 100px; }
table.custom_table.w-c4-110px{ --fixed-column-c4-width: 110px; }
table.custom_table.w-c4-120px{ --fixed-column-c4-width: 120px; }
table.custom_table.w-c4-130px{ --fixed-column-c4-width: 130px; }
table.custom_table.w-c4-140px{ --fixed-column-c4-width: 140px; }
table.custom_table.w-c4-150px{ --fixed-column-c4-width: 150px; }
table.custom_table.w-c4-160px{ --fixed-column-c4-width: 160px; }
table.custom_table.w-c4-170px{ --fixed-column-c4-width: 170px; }
table.custom_table.w-c4-180px{ --fixed-column-c4-width: 180px; }
table.custom_table.w-c4-190px{ --fixed-column-c4-width: 190px; }
table.custom_table.w-c4-200px{ --fixed-column-c4-width: 200px; }
table.custom_table.w-c4-210px{ --fixed-column-c4-width: 210px; }
table.custom_table.w-c4-220px{ --fixed-column-c4-width: 220px; }
table.custom_table.w-c4-230px{ --fixed-column-c4-width: 230px; }
table.custom_table.w-c4-240px{ --fixed-column-c4-width: 240px; }
table.custom_table.w-c4-250px{ --fixed-column-c4-width: 250px; }
table.custom_table.w-c4-260px{ --fixed-column-c4-width: 260px; }
table.custom_table.w-c4-270px{ --fixed-column-c4-width: 270px; }
table.custom_table.w-c4-280px{ --fixed-column-c4-width: 280px; }
table.custom_table.w-c4-290px{ --fixed-column-c4-width: 290px; }
table.custom_table.w-c4-300px{ --fixed-column-c4-width: 300px; }
table.custom_table.w-c4-310px{ --fixed-column-c4-width: 310px; }
table.custom_table.w-c4-320px{ --fixed-column-c4-width: 320px; }
table.custom_table.w-c4-330px{ --fixed-column-c4-width: 330px; }
table.custom_table.w-c4-340px{ --fixed-column-c4-width: 340px; }
table.custom_table.w-c4-350px{ --fixed-column-c4-width: 350px; }
table.custom_table.w-c4-360px{ --fixed-column-c4-width: 360px; }
table.custom_table.w-c4-370px{ --fixed-column-c4-width: 370px; }
table.custom_table.w-c4-380px{ --fixed-column-c4-width: 380px; }
table.custom_table.w-c4-390px{ --fixed-column-c4-width: 390px; }
table.custom_table.w-c4-400px{ --fixed-column-c4-width: 400px; }

table.custom_table.w-c5-10px{ --fixed-column-c5-width: 10px; }
table.custom_table.w-c5-20px{ --fixed-column-c5-width: 20px; }
table.custom_table.w-c5-30px{ --fixed-column-c5-width: 30px; }
table.custom_table.w-c5-40px{ --fixed-column-c5-width: 40px; }
table.custom_table.w-c5-50px{ --fixed-column-c5-width: 50px; }
table.custom_table.w-c5-60px{ --fixed-column-c5-width: 60px; }
table.custom_table.w-c5-70px{ --fixed-column-c5-width: 70px; }
table.custom_table.w-c5-80px{ --fixed-column-c5-width: 80px; }
table.custom_table.w-c5-90px{ --fixed-column-c5-width: 90px; }
table.custom_table.w-c5-100px{ --fixed-column-c5-width: 100px; }
table.custom_table.w-c5-110px{ --fixed-column-c5-width: 110px; }
table.custom_table.w-c5-120px{ --fixed-column-c5-width: 120px; }
table.custom_table.w-c5-130px{ --fixed-column-c5-width: 130px; }
table.custom_table.w-c5-140px{ --fixed-column-c5-width: 140px; }
table.custom_table.w-c5-150px{ --fixed-column-c5-width: 150px; }
table.custom_table.w-c5-160px{ --fixed-column-c5-width: 160px; }
table.custom_table.w-c5-170px{ --fixed-column-c5-width: 170px; }
table.custom_table.w-c5-180px{ --fixed-column-c5-width: 180px; }
table.custom_table.w-c5-190px{ --fixed-column-c5-width: 190px; }
table.custom_table.w-c5-200px{ --fixed-column-c5-width: 200px; }
table.custom_table.w-c5-210px{ --fixed-column-c5-width: 210px; }
table.custom_table.w-c5-220px{ --fixed-column-c5-width: 220px; }
table.custom_table.w-c5-230px{ --fixed-column-c5-width: 230px; }
table.custom_table.w-c5-240px{ --fixed-column-c5-width: 240px; }
table.custom_table.w-c5-250px{ --fixed-column-c5-width: 250px; }
table.custom_table.w-c5-260px{ --fixed-column-c5-width: 260px; }
table.custom_table.w-c5-270px{ --fixed-column-c5-width: 270px; }
table.custom_table.w-c5-280px{ --fixed-column-c5-width: 280px; }
table.custom_table.w-c5-290px{ --fixed-column-c5-width: 290px; }
table.custom_table.w-c5-300px{ --fixed-column-c5-width: 300px; }
table.custom_table.w-c5-310px{ --fixed-column-c5-width: 310px; }
table.custom_table.w-c5-320px{ --fixed-column-c5-width: 320px; }
table.custom_table.w-c5-330px{ --fixed-column-c5-width: 330px; }
table.custom_table.w-c5-340px{ --fixed-column-c5-width: 340px; }
table.custom_table.w-c5-350px{ --fixed-column-c5-width: 350px; }
table.custom_table.w-c5-360px{ --fixed-column-c5-width: 360px; }
table.custom_table.w-c5-370px{ --fixed-column-c5-width: 370px; }
table.custom_table.w-c5-380px{ --fixed-column-c5-width: 380px; }
table.custom_table.w-c5-390px{ --fixed-column-c5-width: 390px; }
table.custom_table.w-c5-400px{ --fixed-column-c5-width: 400px; }

/* c6 */
table.custom_table.w-c6-10px{ --fixed-column-c6-width: 10px; }
table.custom_table.w-c6-20px{ --fixed-column-c6-width: 20px; }
table.custom_table.w-c6-30px{ --fixed-column-c6-width: 30px; }
table.custom_table.w-c6-40px{ --fixed-column-c6-width: 40px; }
table.custom_table.w-c6-50px{ --fixed-column-c6-width: 50px; }
table.custom_table.w-c6-60px{ --fixed-column-c6-width: 60px; }
table.custom_table.w-c6-70px{ --fixed-column-c6-width: 70px; }
table.custom_table.w-c6-80px{ --fixed-column-c6-width: 80px; }
table.custom_table.w-c6-90px{ --fixed-column-c6-width: 90px; }
table.custom_table.w-c6-100px{ --fixed-column-c6-width: 100px; }
table.custom_table.w-c6-110px{ --fixed-column-c6-width: 110px; }
table.custom_table.w-c6-120px{ --fixed-column-c6-width: 120px; }
table.custom_table.w-c6-130px{ --fixed-column-c6-width: 130px; }
table.custom_table.w-c6-140px{ --fixed-column-c6-width: 140px; }
table.custom_table.w-c6-150px{ --fixed-column-c6-width: 150px; }
table.custom_table.w-c6-160px{ --fixed-column-c6-width: 160px; }
table.custom_table.w-c6-170px{ --fixed-column-c6-width: 170px; }
table.custom_table.w-c6-180px{ --fixed-column-c6-width: 180px; }
table.custom_table.w-c6-190px{ --fixed-column-c6-width: 190px; }
table.custom_table.w-c6-200px{ --fixed-column-c6-width: 200px; }
table.custom_table.w-c6-210px{ --fixed-column-c6-width: 210px; }
table.custom_table.w-c6-220px{ --fixed-column-c6-width: 220px; }
table.custom_table.w-c6-230px{ --fixed-column-c6-width: 230px; }
table.custom_table.w-c6-240px{ --fixed-column-c6-width: 240px; }
table.custom_table.w-c6-250px{ --fixed-column-c6-width: 250px; }
table.custom_table.w-c6-260px{ --fixed-column-c6-width: 260px; }
table.custom_table.w-c6-270px{ --fixed-column-c6-width: 270px; }
table.custom_table.w-c6-280px{ --fixed-column-c6-width: 280px; }
table.custom_table.w-c6-290px{ --fixed-column-c6-width: 290px; }
table.custom_table.w-c6-300px{ --fixed-column-c6-width: 300px; }
table.custom_table.w-c6-310px{ --fixed-column-c6-width: 310px; }
table.custom_table.w-c6-320px{ --fixed-column-c6-width: 320px; }
table.custom_table.w-c6-330px{ --fixed-column-c6-width: 330px; }
table.custom_table.w-c6-340px{ --fixed-column-c6-width: 340px; }
table.custom_table.w-c6-350px{ --fixed-column-c6-width: 350px; }
table.custom_table.w-c6-360px{ --fixed-column-c6-width: 360px; }
table.custom_table.w-c6-370px{ --fixed-column-c6-width: 370px; }
table.custom_table.w-c6-380px{ --fixed-column-c6-width: 380px; }
table.custom_table.w-c6-390px{ --fixed-column-c6-width: 390px; }
table.custom_table.w-c6-400px{ --fixed-column-c6-width: 400px; }

/* c7 */
table.custom_table.w-c7-10px{ --fixed-column-c7-width: 10px; }
table.custom_table.w-c7-20px{ --fixed-column-c7-width: 20px; }
table.custom_table.w-c7-30px{ --fixed-column-c7-width: 30px; }
table.custom_table.w-c7-40px{ --fixed-column-c7-width: 40px; }
table.custom_table.w-c7-50px{ --fixed-column-c7-width: 50px; }
table.custom_table.w-c7-60px{ --fixed-column-c7-width: 60px; }
table.custom_table.w-c7-70px{ --fixed-column-c7-width: 70px; }
table.custom_table.w-c7-80px{ --fixed-column-c7-width: 80px; }
table.custom_table.w-c7-90px{ --fixed-column-c7-width: 90px; }
table.custom_table.w-c7-100px{ --fixed-column-c7-width: 100px; }
table.custom_table.w-c7-110px{ --fixed-column-c7-width: 110px; }
table.custom_table.w-c7-120px{ --fixed-column-c7-width: 120px; }
table.custom_table.w-c7-130px{ --fixed-column-c7-width: 130px; }
table.custom_table.w-c7-140px{ --fixed-column-c7-width: 140px; }
table.custom_table.w-c7-150px{ --fixed-column-c7-width: 150px; }
table.custom_table.w-c7-160px{ --fixed-column-c7-width: 160px; }
table.custom_table.w-c7-170px{ --fixed-column-c7-width: 170px; }
table.custom_table.w-c7-180px{ --fixed-column-c7-width: 180px; }
table.custom_table.w-c7-190px{ --fixed-column-c7-width: 190px; }
table.custom_table.w-c7-200px{ --fixed-column-c7-width: 200px; }
table.custom_table.w-c7-210px{ --fixed-column-c7-width: 210px; }
table.custom_table.w-c7-220px{ --fixed-column-c7-width: 220px; }
table.custom_table.w-c7-230px{ --fixed-column-c7-width: 230px; }
table.custom_table.w-c7-240px{ --fixed-column-c7-width: 240px; }
table.custom_table.w-c7-250px{ --fixed-column-c7-width: 250px; }
table.custom_table.w-c7-260px{ --fixed-column-c7-width: 260px; }
table.custom_table.w-c7-270px{ --fixed-column-c7-width: 270px; }
table.custom_table.w-c7-280px{ --fixed-column-c7-width: 280px; }
table.custom_table.w-c7-290px{ --fixed-column-c7-width: 290px; }
table.custom_table.w-c7-300px{ --fixed-column-c7-width: 300px; }
table.custom_table.w-c7-310px{ --fixed-column-c7-width: 310px; }
table.custom_table.w-c7-320px{ --fixed-column-c7-width: 320px; }
table.custom_table.w-c7-330px{ --fixed-column-c7-width: 330px; }
table.custom_table.w-c7-340px{ --fixed-column-c7-width: 340px; }
table.custom_table.w-c7-350px{ --fixed-column-c7-width: 350px; }
table.custom_table.w-c7-360px{ --fixed-column-c7-width: 360px; }
table.custom_table.w-c7-370px{ --fixed-column-c7-width: 370px; }
table.custom_table.w-c7-380px{ --fixed-column-c7-width: 380px; }
table.custom_table.w-c7-390px{ --fixed-column-c7-width: 390px; }
table.custom_table.w-c7-400px{ --fixed-column-c7-width: 400px; }

/* c8 */
table.custom_table.w-c8-10px{ --fixed-column-c8-width: 10px; }
table.custom_table.w-c8-20px{ --fixed-column-c8-width: 20px; }
table.custom_table.w-c8-30px{ --fixed-column-c8-width: 30px; }
table.custom_table.w-c8-40px{ --fixed-column-c8-width: 40px; }
table.custom_table.w-c8-50px{ --fixed-column-c8-width: 50px; }
table.custom_table.w-c8-60px{ --fixed-column-c8-width: 60px; }
table.custom_table.w-c8-70px{ --fixed-column-c8-width: 70px; }
table.custom_table.w-c8-80px{ --fixed-column-c8-width: 80px; }
table.custom_table.w-c8-90px{ --fixed-column-c8-width: 90px; }
table.custom_table.w-c8-100px{ --fixed-column-c8-width: 100px; }
table.custom_table.w-c8-110px{ --fixed-column-c8-width: 110px; }
table.custom_table.w-c8-120px{ --fixed-column-c8-width: 120px; }
table.custom_table.w-c8-130px{ --fixed-column-c8-width: 130px; }
table.custom_table.w-c8-140px{ --fixed-column-c8-width: 140px; }
table.custom_table.w-c8-150px{ --fixed-column-c8-width: 150px; }
table.custom_table.w-c8-160px{ --fixed-column-c8-width: 160px; }
table.custom_table.w-c8-170px{ --fixed-column-c8-width: 170px; }
table.custom_table.w-c8-180px{ --fixed-column-c8-width: 180px; }
table.custom_table.w-c8-190px{ --fixed-column-c8-width: 190px; }
table.custom_table.w-c8-200px{ --fixed-column-c8-width: 200px; }
table.custom_table.w-c8-210px{ --fixed-column-c8-width: 210px; }
table.custom_table.w-c8-220px{ --fixed-column-c8-width: 220px; }
table.custom_table.w-c8-230px{ --fixed-column-c8-width: 230px; }
table.custom_table.w-c8-240px{ --fixed-column-c8-width: 240px; }
table.custom_table.w-c8-250px{ --fixed-column-c8-width: 250px; }
table.custom_table.w-c8-260px{ --fixed-column-c8-width: 260px; }
table.custom_table.w-c8-270px{ --fixed-column-c8-width: 270px; }
table.custom_table.w-c8-280px{ --fixed-column-c8-width: 280px; }
table.custom_table.w-c8-290px{ --fixed-column-c8-width: 290px; }
table.custom_table.w-c8-300px{ --fixed-column-c8-width: 300px; }
table.custom_table.w-c8-310px{ --fixed-column-c8-width: 310px; }
table.custom_table.w-c8-320px{ --fixed-column-c8-width: 320px; }
table.custom_table.w-c8-330px{ --fixed-column-c8-width: 330px; }
table.custom_table.w-c8-340px{ --fixed-column-c8-width: 340px; }
table.custom_table.w-c8-350px{ --fixed-column-c8-width: 350px; }
table.custom_table.w-c8-360px{ --fixed-column-c8-width: 360px; }
table.custom_table.w-c8-370px{ --fixed-column-c8-width: 370px; }
table.custom_table.w-c8-380px{ --fixed-column-c8-width: 380px; }
table.custom_table.w-c8-390px{ --fixed-column-c8-width: 390px; }
table.custom_table.w-c8-400px{ --fixed-column-c8-width: 400px; }

table.custom_table{
    width: 100%;
    --fixed-column-c1-width: 110px;
    --fixed-column-c2-width: 200px;
    --fixed-column-c3-width: 60px;
    --fixed-column-c4-width: 20em;
    --fixed-column-c5-width: 20em;
    --fixed-column-c6-width: 20em;
    --fixed-column-c7-width: 20em;
    --fixed-column-c8-width: 20em;
    --custom-table-fixed-column-left: 0px;
    --custom-table-fixed-column-top: 0px;

    --fixed-column-width-0: var(--custom-table-fixed-column-left);
    --fixed-column-width-1: var(--fixed-column-c1-width);
    --fixed-column-width-2: var(--fixed-column-c2-width);
    --fixed-column-width-3: var(--fixed-column-c3-width);
    --fixed-column-width-4: var(--fixed-column-c4-width);
    --fixed-column-width-5: var(--fixed-column-c5-width);
    --fixed-column-width-6: var(--fixed-column-c6-width);
    --fixed-column-width-7: var(--fixed-column-c7-width);
    --fixed-column-width-8: var(--fixed-column-c8-width);
    --fixed-border:1px solid var(--bs-gray-300);

    --fixed-column-left-1: var(--fixed-column-width-0);
    --fixed-column-left-2: calc(var(--fixed-column-left-1) + var(--fixed-column-width-1));
    --fixed-column-left-3: calc(var(--fixed-column-left-2) + var(--fixed-column-width-2));
    --fixed-column-left-4: calc(var(--fixed-column-left-3) + var(--fixed-column-width-3));
    --fixed-column-left-5: calc(var(--fixed-column-left-4) + var(--fixed-column-width-4));
    --fixed-column-left-6: calc(var(--fixed-column-left-5) + var(--fixed-column-width-5));
    --fixed-column-left-7: calc(var(--fixed-column-left-6) + var(--fixed-column-width-6));
    --fixed-column-left-8: calc(var(--fixed-column-left-7) + var(--fixed-column-width-7));
}

div.custom-table-fixed-toolbar{
    position:-webkit-sticky; position: sticky; left: 0px; z-index: 3;  
}


@media(min-width: 992px) {
    table.custom_table{
        /* --custom-table-fixed-column-left: calc(var(--bs-app-sidebar-width) + 1px); */
        --custom-table-fixed-column-left: calc(var(--bs-app-sidebar-width) + var(--bs-app-sidebar-gap-start, 0px) + var(--bs-app-sidebar-gap-end, 0px));
        --custom-table-fixed-column-top: calc(var(--bs-app-header-height) + 1px);
    }
    
    div.custom-table-fixed-toolbar{
        /* left: calc(var(--bs-app-sidebar-width) + 1px);   */
        left: calc(var(--bs-app-sidebar-width) + var(--bs-app-sidebar-gap-start, 0px) + var(--bs-app-sidebar-gap-end, 0px));
    }
}

table.custom_table.popup-page{
    /* --custom-table-fixed-column-left: calc(var(--bs-app-sidebar-width) + 1px); */
    --custom-table-fixed-column-left: 0px;
    --custom-table-fixed-column-top: 60px;
}

table.custom_table td, table.custom_table th{
    border: var(--fixed-border);
}


/* fixed-header */
table.fixed-header thead { position:-webkit-sticky; position: sticky; top: var(--custom-table-fixed-column-top); outline: var(--fixed-border); border: var(--fixed-border); z-index:2; }
table.fixed-header thead tr th{ outline: var(--fixed-border); border: var(--fixed-border); }

table.fixed-cols-1 thead tr th:nth-child(1), table.fixed-cols-1 tbody tr td:nth-child(1),
table.fixed-cols-2 thead tr th:nth-child(-n+2), table.fixed-cols-2 tbody tr td:nth-child(-n+2),
table.fixed-cols-3 thead tr th:nth-child(-n+3), table.fixed-cols-3 tbody tr td:nth-child(-n+3),
table.fixed-cols-4 thead tr th:nth-child(-n+4), table.fixed-cols-4 tbody tr td:nth-child(-n+4),
table.fixed-cols-5 thead tr th:nth-child(-n+5), table.fixed-cols-5 tbody tr td:nth-child(-n+5),
table.fixed-cols-6 thead tr th:nth-child(-n+6), table.fixed-cols-6 tbody tr td:nth-child(-n+6),
table.fixed-cols-7 thead tr th:nth-child(-n+7), table.fixed-cols-7 tbody tr td:nth-child(-n+7),
table.fixed-cols-8 thead tr th:nth-child(-n+8), table.fixed-cols-8 tbody tr td:nth-child(-n+8)
{ position:-webkit-sticky; position: sticky; outline: var(--fixed-border); border: var(--fixed-border); }

/* fixed-col-1 */
table.fixed-cols-1 thead tr th:nth-child(1), table.fixed-cols-1 tbody tr td:nth-child(1){ left:var(--fixed-column-left-1); outline: var(--fixed-border); border: var(--fixed-border); z-index:1; }

/* fixed-col-2 */
table.fixed-cols-2 thead tr th:nth-child(1), table.fixed-cols-2 tbody tr td:nth-child(1),
table.fixed-cols-3 thead tr th:nth-child(1), table.fixed-cols-3 tbody tr td:nth-child(1),
table.fixed-cols-4 thead tr th:nth-child(1), table.fixed-cols-4 tbody tr td:nth-child(1),
table.fixed-cols-5 thead tr th:nth-child(1), table.fixed-cols-5 tbody tr td:nth-child(1),
table.fixed-cols-6 thead tr th:nth-child(1), table.fixed-cols-6 tbody tr td:nth-child(1),
table.fixed-cols-7 thead tr th:nth-child(1), table.fixed-cols-7 tbody tr td:nth-child(1),
table.fixed-cols-8 thead tr th:nth-child(1), table.fixed-cols-8 tbody tr td:nth-child(1)
{
    left:var(--fixed-column-left-1);
    min-width:var(--fixed-column-width-1);
    max-width:var(--fixed-column-width-1);
    outline: var(--fixed-border);
    border: var(--fixed-border);
    z-index:1;
}
table.fixed-cols-2 thead tr th:nth-child(2), table.fixed-cols-2 tbody tr td:nth-child(2) {
    min-width:var(--fixed-column-width-2);
    max-width:var(--fixed-column-width-2);
    left:var(--fixed-column-left-2);
    outline: var(--fixed-border); 
    border: var(--fixed-border);
    z-index:1;
}

/* fixed-col-3 */
table.fixed-cols-3 thead tr th:nth-child(2), table.fixed-cols-3 tbody tr td:nth-child(2),
table.fixed-cols-4 thead tr th:nth-child(2), table.fixed-cols-4 tbody tr td:nth-child(2),
table.fixed-cols-5 thead tr th:nth-child(2), table.fixed-cols-5 tbody tr td:nth-child(2),
table.fixed-cols-6 thead tr th:nth-child(2), table.fixed-cols-6 tbody tr td:nth-child(2),
table.fixed-cols-7 thead tr th:nth-child(2), table.fixed-cols-7 tbody tr td:nth-child(2),
table.fixed-cols-8 thead tr th:nth-child(2), table.fixed-cols-8 tbody tr td:nth-child(2)
{ left:var(--fixed-column-left-2); min-width:var(--fixed-column-width-2); max-width:var(--fixed-column-width-2); outline: var(--fixed-border); border: var(--fixed-border); z-index:1; }
table.fixed-cols-3 thead tr th:nth-child(3), table.fixed-cols-3 tbody tr td:nth-child(3) {
    min-width:var(--fixed-column-width-3);
    max-width:var(--fixed-column-width-3);
    left:var(--fixed-column-left-3);
    outline: var(--fixed-border); 
    border: var(--fixed-border);
    z-index:1;
}
/* fixed-col-4 */
table.fixed-cols-4 thead tr th:nth-child(3), table.fixed-cols-4 tbody tr td:nth-child(3),
table.fixed-cols-5 thead tr th:nth-child(3), table.fixed-cols-5 tbody tr td:nth-child(3),
table.fixed-cols-6 thead tr th:nth-child(3), table.fixed-cols-6 tbody tr td:nth-child(3),
table.fixed-cols-7 thead tr th:nth-child(3), table.fixed-cols-7 tbody tr td:nth-child(3),
table.fixed-cols-8 thead tr th:nth-child(3), table.fixed-cols-8 tbody tr td:nth-child(3)
{ left:var(--fixed-column-left-3); min-width:var(--fixed-column-width-3); max-width:var(--fixed-column-width-3); outline: var(--fixed-border); border: var(--fixed-border); z-index:1; }
table.fixed-cols-4 thead tr th:nth-child(4), table.fixed-cols-4 tbody tr td:nth-child(4) { 
    min-width:var(--fixed-column-width-4);
    max-width:var(--fixed-column-width-4);
    left:var(--fixed-column-left-4);
    outline: var(--fixed-border); 
    border: var(--fixed-border);
    z-index:1;
}
/* fixed-col-5 */
table.fixed-cols-5 thead tr th:nth-child(4), table.fixed-cols-5 tbody tr td:nth-child(4),
table.fixed-cols-6 thead tr th:nth-child(4), table.fixed-cols-6 tbody tr td:nth-child(4),
table.fixed-cols-7 thead tr th:nth-child(4), table.fixed-cols-7 tbody tr td:nth-child(4),
table.fixed-cols-8 thead tr th:nth-child(4), table.fixed-cols-8 tbody tr td:nth-child(4)
{ left:var(--fixed-column-left-4); min-width:var(--fixed-column-width-4); max-width:var(--fixed-column-width-4); outline: var(--fixed-border); border: var(--fixed-border); z-index:1; }
table.fixed-cols-5 thead tr th:nth-child(5), table.fixed-cols-5 tbody tr td:nth-child(5) { 
    min-width:var(--fixed-column-width-5);
    max-width:var(--fixed-column-width-5);
    left:var(--fixed-column-left-5);
    outline: var(--fixed-border); 
    border: var(--fixed-border);
    z-index:1;
}
/* fixed-col-6 */
table.fixed-cols-6 thead tr th:nth-child(5), table.fixed-cols-6 tbody tr td:nth-child(5),
table.fixed-cols-7 thead tr th:nth-child(5), table.fixed-cols-7 tbody tr td:nth-child(5),
table.fixed-cols-8 thead tr th:nth-child(5), table.fixed-cols-8 tbody tr td:nth-child(5)
{ left:var(--fixed-column-left-5); min-width:var(--fixed-column-width-5); max-width:var(--fixed-column-width-5); outline: var(--fixed-border); border: var(--fixed-border); z-index:1; }
table.fixed-cols-6 thead tr th:nth-child(6), table.fixed-cols-6 tbody tr td:nth-child(6) {
    min-width:var(--fixed-column-width-6);
    max-width:var(--fixed-column-width-6);
    left:var(--fixed-column-left-6);
    outline: var(--fixed-border); 
    border: var(--fixed-border);
    z-index:1;
}
/* fixed-col-7 */
table.fixed-cols-7 thead tr th:nth-child(6), table.fixed-cols-7 tbody tr td:nth-child(6),
table.fixed-cols-8 thead tr th:nth-child(6), table.fixed-cols-8 tbody tr td:nth-child(6)
{ left:var(--fixed-column-left-6); min-width:var(--fixed-column-width-6); max-width:var(--fixed-column-width-6); outline: var(--fixed-border); border: var(--fixed-border); z-index:1; }
table.fixed-cols-7 thead tr th:nth-child(7), table.fixed-cols-7 tbody tr td:nth-child(7) { 
    min-width:var(--fixed-column-width-7);
    max-width:var(--fixed-column-width-7);
    left:var(--fixed-column-left-7);
    outline: var(--fixed-border); 
    border: var(--fixed-border);
    z-index:1;
}
/* fixed-col-8 */
table.fixed-cols-8 thead tr th:nth-child(7), table.fixed-cols-8 tbody tr td:nth-child(7)
{ left:var(--fixed-column-left-7); min-width:var(--fixed-column-width-7); max-width:var(--fixed-column-width-7); outline: var(--fixed-border); border: var(--fixed-border); z-index:1; }
table.fixed-cols-8 thead tr th:nth-child(8), table.fixed-cols-8 tbody tr td:nth-child(8) {
    min-width:var(--fixed-column-width-8);
    max-width:var(--fixed-column-width-8);
    left:var(--fixed-column-left-8);
    outline: var(--fixed-border); 
    border: var(--fixed-border);
    z-index:1;
}