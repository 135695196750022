.PopupNotice{
    .title{
        cursor: pointer;
    }
    .popup{
        display: none;
    }

    &:hover{
        text-decoration: underline;
        .popup{
            display: block;
        }

    }
}