
.tree_expanded:hover{
    width:460px;
}
div#kt_app_sidebar:has(.tree_expanded:hover),
div#kt_app_sidebar_menu_wrapper:has(.tree_expanded:hover){
    width: 500px;
}

.tree_expanded .menu-title > div > div {
    width: 170px;
}
.tree_expanded:hover .menu-title > div > div {
    width: 420px;
}