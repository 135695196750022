.custom-table-filter div,
.custom-table-filter span,
.custom-table-filter input,
.custom-table-filter select,
.custom-table-filter textarea
{
    font-weight:normal;
    font-size:9pt;
    padding:0 0; 
}
.custom-table-filter input{
    min-width: 40px;
    padding: 0 10px;
}
.custom-table-filter .form-select {
    background-position: right 0rem center;
    min-width:50px;
    height:33px;
    padding: 0 10px;
    box-shadow: none;
}
.custom-table-filter .multi-select,
.custom-table-filter .multi-select .dropdown-container{
    background-color: var(--bs-body-bg);
    border-color:var(--bs-gray-300);
    border-radius: 0.475rem;
}

.custom-table-filter .multi-select .dropdown-heading{
    padding:0.25em 0px;
    height:inherit;
}

.custom-table-filter .multi-select .dropdown-content {
    width:initial;
    max-width:initial;
}


/* .custom-table-filter select, */
.custom-table-filter input
{
    height:33px;
}

.custom-table-filter textarea
{
    /* padding:0.3em; */
    padding: 0.5em 0.1em 0em 0.1em;
    min-height: inherit;
    height:33px;
}
.custom-table-filter textarea:placeholder-shown { overflow:hidden; }

.custom-table-filter .input-group
{
    min-width:100px;
}

.custom-table-filter .form-control{
    min-width:40px;
}

.custom-table-filter .dropdown-content {
    width:initial;
    max-width:initial;
    z-index:4;
}

.custom-table-filter .dropdown-content .select-panel ul.options *
{
    padding:inherit; 
    margin:inherit;
    padding-right:0.35rem;
    line-height:inherit;
    white-space: nowrap;
}

.custom-table-filter .dropdown-content .select-panel ul.options input[type=checkbox]
{
    width:14px;
    height:14px;
    margin:inherit;
}

.custom-table-filter .dropdown-content .select-panel ul.options label
{
    margin:2px 0;
}

.text-left {
text-align: left !important; }

.text-right {
text-align: right !important; }

.text-center {
text-align: center !important; }

/* <div class="dropdown-content">
<div class="panel-content">
<div class="select-panel" role="listbox">
<div class="search">
    <input placeholder="Search" type="text" aria-describedby="Search" tabindex="0" value="">
    <button type="button" class="search-clear-button" hidden="" aria-label="Clear Search">
        <svg width="24" height="24" fill="none" stroke="currentColor" stroke-width="2" class="dropdown-search-clear-icon gray">
            <line x1="18" y1="6" x2="6" y2="18"></line>
            <line x1="6" y1="6" x2="18" y2="18"></line>
        </svg>
    </button>
</div>
<ul class="options">
<label class="select-item " role="option" aria-selected="false" tabindex="1">
    <div class="item-renderer "><input type="checkbox" tabindex="-1"><span>Select All</span></div></label>
    <li><label class="select-item " role="option" aria-selected="false" tabindex="2"><div class="item-renderer "><input type="checkbox" tabindex="-1"><span>ON</span></div></label></li>
    <li><label class="select-item " role="option" aria-selected="false" tabindex="3"><div class="item-renderer "><input type="checkbox" tabindex="-1"><span>OFF</span></div></label></li>
    <li><label class="select-item selected" role="option" aria-selected="true" tabindex="4"><div class="item-renderer "><input type="checkbox" tabindex="-1" checked=""><span>OFF_BY_AD_ACCOUNT_ADMIN_STOP</span></div></label></li>
    <li><label class="select-item selected" role="option" aria-selected="true" tabindex="5"><div class="item-renderer "><input type="checkbox" tabindex="-1" checked=""><span>OFF_BY_CAMPAIGN_DAILY_BUDGET</span></div></label></li><
/ul></div></div></div> */