.rdrCalendarWrapper {
    color: var(--bs-gray-900) !important;
    background: var(--bs-body-bg) !important;
}
  
.rdrDayNumber span{
    color: var(--bs-gray-900) !important;
}

.rdrDayDisabled .rdrDayNumber span{
    color: var(--bs-gray-400) !important;
}
.rdrDayDisabled{
    /* background-color: var(--bs-gray-200) !important; */
    background-color: var(--bs-body-bg);
}

.rdrMonthAndYearPickers select {
    color: var(--bs-gray-600) !important;
}

.rdrNextPrevButton{
    color: var(--bs-gray-900) !important;
    background-color: var(--bs-gray-300) !important;

}

.rdrNextPrevButton.rdrNextButton i{
    border-color: transparent transparent transparent var(--bs-gray-600) !important;
}

.rdrNextPrevButton.rdrPprevButton i{
    border-color: transparent var(--bs-gray-600) transparent transparent !important;
}

.rdrStaticRange{
    background-color: var(--bs-body-bg) !important;
    border-bottom: 1px solid var(--bs-gray-300) !important;
}
.rdrStaticRange:hover .rdrStaticRangeLabel{
    background-color: var(--bs-gray-200) !important;
}

.rdrDefinedRangesWrapper{
    background-color: var(--bs-body-bg) !important;
    border-right: 1px solid var(--bs-gray-300) !important;
}
