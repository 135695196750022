.table{
    width:100%;
    thead{
        position:sticky;
        top:0;
        background-color: var(--bs-white);
        tr{
            outline: 1px solid var(--bs-gray-300);
            th{
                font-weight: bold;
                text-align: center;
                color: var(--bs-gray-500);
                padding:0.735em;
            }
        }
    }
    tbody{
        tr{
            td{
                padding:0.735em;
            }
        }

        tr:nth-child(n+2){
            border-top: 1px dotted var(--bs-gray-300);
        }
    }
}

.TabMenu{
    display: flex;

    .TabMenuItem{
        cursor: pointer;
        font-weight: bold;
        margin-right: 1em;

        &:hover{
            text-shadow: 0px 0px 1px var(--bs-gray-600);
        }
    }

}


.ReportTable{
    width: 100%;
    text-align: right;
    th:nth-child(1), td:nth-child(1){
        text-align: center;
        min-width: 2.35em;
        position: sticky;
        position: -webkit-sticky;
        left: 0;
        background-color: var(--bs-card-bg);
    }
    th:nth-child(2), td:nth-child(2){
        text-align: left;
        padding-left: 0.75em;
        min-width: 200px;
        position: sticky;
        position: -webkit-sticky;
        left: 2.35em;
        background-color: var(--bs-card-bg);
    }
    th, td{ 
        padding: 1em 0.75em;
        line-height: 200%;
    }
    th{
        color: var(--bs-gray-600);
        font-weight: bold;
        border-bottom: 1px solid var(--bs-gray-400);
    }

    td{
        min-width: 140px;
        border-top: 1px solid var(--bs-gray-300);
        text-wrap: nowrap;

        &:nth-child(1){
            border-top: none;
        }
    }
    .mediaType{
        border-top: 1px solid var(--bs-gray-300);
        td:nth-child(2){
            font-weight: bold;
        }
    }
    .client{
        display: none;
        &.show{
            display:table-row;
        }
    }

}