$--bs-btn-disabled-opacity: 0.65;

.input{
    font-size: 1rem;
    box-shadow: none;
    white-space: nowrap;
    align-items: center;
    padding: calc(0.55rem + 1px) calc(1.25rem + 1px);
    height: 35px;

    &[data-type=sm]{
        padding: calc(0.13rem + 1px) !important;
        height: 24.47px !important;
    }
    
    font-size: 1rem !important;
    color: var(--bs-dark);
    &::placeholder{
        color: var(--bs-gray-600);
    }
    &:focus,&:hover{
        border-color: var(--bs-primary);
    }

    &[type=number]{
        text-align:right;
    }

    &:disabled{
        border-color: var(--bs-gray-300) !important;
    }
}

.search{
    $p: &;
    text-align: left;
    & > i.search_icon{
        position: absolute;
        margin: 0.85em 0 0 0.7em;
    }
    &:has(input[data-icon-hide=true]){
        & > i.search_icon{
            display: none;
        }
    }

    & > i.remove_icon{
        position: absolute;
        right: 0.45em;
        margin: 0.85em;
        cursor: pointer;

        &:hover{
            font-weight: bold;
            color:white;
        }
    }
    &:has(input[data-value=""]){
        & > i.remove_icon{
            display: none;
        }
    }
    & > input:not([data-icon-hide=true]){
        padding-left: 2em;
    }
}
