.toggle-switch {
    position: relative;
    width: 100%;
    display: inline-block;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    text-align: left;
        &-checkbox {
        display: none;
    }
    &-label {
        display: block;
        overflow: hidden;
        cursor: pointer;
        border: 0 solid #bbb;
        border-radius: 1em;
        margin: 0;
    }
    &-inner {
        display: block;
        width: 200%;
        margin-left: -100%;
        transition: margin 0.3s ease-in 0s;
        &:before,
        &:after {
            display: block;
            float: left;
            width: 50%;
            height: 2em;
            padding: 0;
            line-height: 2em;
            font-size: 0.83em;
            color: white;
            // font-weight: bold;
            box-sizing: border-box;
            padding-top: 0.1em;
            
        }
        &:before {
            content: attr(data-yes);
            text-transform: uppercase;
            padding-left: 10px;
            // background-color: #f90;
            background-color: var(--bs-info-active);
            color: #fff;
        }
    }
    &-disabled {
        background-color: #ddd;
        cursor: not-allowed;
        &:before {
            background-color: #ddd;
            cursor: not-allowed;
        }
    }
    &-inner:after {
        content: attr(data-no);
        text-transform: uppercase;
        padding-right: 10px;
        background-color: #bbb;
        color: #fff;
        text-align: right;
    }
    &-switch {
        display: block;
        width: 1em;
        margin: 0.3em;
        background: #fff;
        position: absolute;
        top: 0;
        bottom: 0;
        right: calc(100% - 1.6em);
        border: 0 solid #bbb;
        border-radius: 20px;
        transition: all 0.3s ease-in 0s;
    }
    &-checkbox:checked + &-label {
        .toggle-switch-inner {
            margin-left: 0;
        }
        .toggle-switch-switch {
            right: 0px;
        }
    }
}