/*
    Calendar & DateRangePicker
*/
.inputCalendar {
    display: flex;
    cursor: pointer;
    width: 10.3em;
    i{
        font-size: 1.4em;
        margin: 0.7em 0 0 -1.5em;
    }
}

.inputRangePicker {
    display: flex;
    cursor: pointer;
    width: 18em;
    i{
        font-size: 1.4em;
        margin: auto;
        margin-left: -1.5em;
        // align-content: center; //Windows에서 동작안함
    }
}

.inputRangePickerSmall {
    height: 2.677em;
    display: flex;
    cursor: pointer;
    width: 18em;
    i{
        font-size: 1.4em;
        margin: 0.4em 0 0 -1.5em;

    }
}

.DateRangePickerButton {
    text-align: right;
    border-top: 1px solid var(--bs-border-color);
    background-color: var(--bs-body-bg);
}



