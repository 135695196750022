$--bs-btn-disabled-opacity: 0.65;

.btn{
    box-shadow: none;
    cursor: pointer;
    white-space: nowrap;

    padding: calc(0.55rem + 1px) calc(1.25rem + 1px);
    padding-top: 0;
    padding-bottom: 0;
    margin: 0;
    border-radius: 0.475rem;
    
    &[data-type=icon]{
        padding: calc(0.55rem + 1px);
        min-width: 35px !important;
    }

    &[data-type=sm]{
        padding: calc(0.13rem + 1px) !important;
        height: 24.47px !important;
    }

    outline: none !important;

    height: 35px !important;
    font-size: 1rem !important;
    
    &[data-variant=primary]{
        color: var(--bs-white) !important;
        background-color: var(--bs-primary);
        border-color: var(--bs-primary) !important;
        & *:not([data-fixed=true]){color: var(--bs-white) !important;}
        &:after{color: var(--bs-white) !important;}
        & *:not([data-fixed=true])::before{color: var(--bs-white) !important;}

        &:focus{
            background-color: var(--bs-primary) !important;
            border-color: var(--bs-primary) !important;
        }
        &:hover{
            background-color: var(--bs-primary-active) !important;
            border-color: var(--bs-primary-active) !important;
        }

        &:disabled{
            cursor: default;
            background-color: var(--bs-primary) !important;
            border-color: var(--bs-primary) !important;
            opacity: $--bs-btn-disabled-opacity;
        }
    }

    &[data-variant=light]{
        color: var(--bs-dark) !important;
        background-color: var(--bs-light);
        border-color: var(--bs-gray-300) !important;
        &:after{color: var(--bs-dark) !important;}
        & *::before{color: var(--bs-dark) !important;}

        &:focus{
            background-color: var(--bs-light) !important;
            border-color: var(--bs-gray-300) !important;
        }
        &:hover{
            background-color: var(--bs-primary-light) !important;
            color: var(--bs-primary) !important;
            border-color: var(--bs-primary) !important;
            & *:not([data-fixed=true]){color: var(--bs-primary) !important;}
            &:after{color: var(--bs-primary) !important;}
            & *:not([data-fixed=true])::before{color: var(--bs-primary) !important;}
        }

        &:disabled{
            cursor: default;
            color: var(--bs-dark) !important;
            background-color: var(--bs-light) !important;
            border-color: var(--bs-gray-300) !important;
            opacity: $--bs-btn-disabled-opacity;
            & *:not([data-fixed=true]){color: var(--bs-dark) !important;}
            &:after{color: var(--bs-dark) !important;}
            & *:not([data-fixed=true])::before{color: var(--bs-dark) !important; opacity: $--bs-btn-disabled-opacity;}
        }
    }
    &[data-variant=secondary]{
        color: var(--bs-dark) !important;
        background-color: var(--bs-secondary);
        border-color: var(--bs-secondary) !important;

        &:focus{
            background-color: var(--bs-secondary);
            border-color: var(--bs-secondary) !important;
        }

        &:hover{
            background-color: var(--bs-secondary-active) !important;
            border-color: var(--bs-secondary-active) !important;
        }

        &:disabled{
            cursor: default;
            background-color: var(--bs-secondary) !important;
            border-color: var(--bs-secondary) !important;
            opacity: $--bs-btn-disabled-opacity;
        }
    }

    // 지정색 표시
    &[data-color=info]{
        color: var(--bs-info) !important;
        border-color: var(--bs-info) !important;
        & *{color: var(--bs-info) !important;}
        &:after{color: var(--bs-info) !important;}
        & *:not([data-fixed=true])::before{color: var(--bs-info) !important;}
    }
}
