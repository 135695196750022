.item_badge_button{
    & > span:hover{
        color:var(--bs-white);
    }
}

.sidebar{
    div[id=kt_app_sidebar_menu_wrapper]{
        // 스크롤바 영역에 대한 설정
        &::-webkit-scrollbar{
        }

        // 스크롤바 막대에 대한 설정
        &::-webkit-scrollbar-thumb{
            background-color:var(--bs-gray);
        }

        // 스크롤바 뒷 배경에 대한 설정
        &::-webkit-scrollbar-track{
        }
    }
}

.tree_item{
    .tree_item_bar{
        height: 1.35em;
        width: 1.35em;
        // overflow: hidden;

        &::before, &::after{
            font-size: 0;
            border-left: 1px solid var(--bs-gray-600);
            width: 10px;
            display: block;
            margin-left: 4px;
        }

        &::before{
            content: "";
            padding-top: 9px;
            border-bottom: 1px solid var(--bs-gray-600);
        }
    }
    & > div:not(:last-child){
        .tree_item_bar{
            &::after{
                content: "";
                padding-top: 20px;
            }
        }

    }
}

.more_button{
    padding: 0.5em 0;
    color: rgb(157, 157, 166);
    cursor: pointer;
    &:hover{
        color: white;
    }
}
