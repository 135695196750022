.multiselect_toggle{
    text-align: left;
    &::after{ display:none; }
}
.multiselect_toggle_down_icon{
    float:'right';
    position:'relative';
    margin-right:'3px';
}

.multiselect_remove_item{
    &:hover{
        background-color: var(--bs-bg-active);
    }
}
.multiselect_remove_icon{
    cursor:pointer;
    &:hover{
        font-weight:bold;
        color:var(--bs-text-active)
    }
}

.multiselect_item{
    &:hover{
        color: var(--bs-dropdown-link-hover-color);
        background-color: var(--bs-dropdown-link-hover-bg);
    }

}