
.warning_label{
    color:var(--bs-danger);
    // &:hover{
    //     color: var(--bs-danger-active);
    // }
    &  i::before{
        color:var(--bs-danger);
    }
}
.warning_icon{
    color:var(--bs-danger);
    margin-right: 0.35em;
    // &:hover{
    //     color: var(--bs-danger-active);
    // }
}
