.table{
    width:100%;
    thead{
        position:sticky;
        top:0;
        background-color: var(--bs-body-bg);
        tr{
            outline: 1px solid var(--bs-gray-300);
            th{
                font-weight: bold;
                text-align: center;
                color: var(--bs-gray-600);
                padding:0.735em;
            }
        }
    }
    tbody{
        tr{
            td{
                padding:0.735em;
            }
        }

        tr:nth-child(n+1){
            border-bottom: 1px dotted var(--bs-gray-300);
        }
    }
}

.TabMenu{
    display: flex;

    .TabMenuItem{
        cursor: pointer;
        font-weight: bold;
        margin-right: 1em;

        &:hover{
            text-shadow: 0px 0px 1px var(--bs-gray-600);
        }
    }

}

.bizmoney{
    & > thead > tr > th:nth-child(1), & > tbody > tr > td:nth-child(1){ width:40%; }
    & > thead > tr > th:nth-child(2), & > tbody > tr > td:nth-child(2){ width:30%; }
    & > thead > tr > th:nth-child(3), & > tbody > tr > td:nth-child(3){ width:30%; }
}

