.select{
    max-height: 200px;
    .item{
        i{
            color: var(--bs-text);
            font-size: 1.2em;
        }
        .remove{
            float: right;
            padding-top: 0.125em;
            cursor: pointer;
            &:hover{
                font-weight: 900;
            }
        }
        border-radius: 0.75em;
        padding: 0.725em 0.725em 0.725em 1em;
        background-color: var(--bs-gray-200);
    }
}

.list{
    max-height: 200px;

    & > .item{
        padding: 0.725em 0.725em 0.725em 1em;
        border-radius: 0.75em;
        cursor: pointer;

        &:hover{
            background-color: var(--bs-gray-200);
        }
        
    }
}

