.table{
    th,td{
        &:nth-child(1){ width:30px; max-width:30px; }
        &:nth-child(2){ width:150px; }
        &:nth-child(3){ width:150px; text-align: center; }
        &:nth-child(4){ width:150px; text-align: center; }
        &:nth-child(5){ width:150px; text-align: center; }
        &:nth-child(6){ width:150px; text-align: center; }
        &:nth-child(7){ width:150px; text-align: right; }
        &:nth-child(8){ width:150px; text-align: right; }
        &:nth-child(9){ width:150px; text-align: right; }
    }

    width:100%;
    thead{
        tr{
            border-bottom: 1px solid;
            th{
                font-weight: bold;
                text-align: center;
                color: var(--bs-gray-600);
                padding:0.735em;
            }
        }
    }
    tbody{
        tr{
            td{
                padding:1em 1em;
            }
        }
        // 광고주 테이블 기본 줄
        tr:nth-child(n+2) td{
            border-top: 1px solid var(--bs-gray-300);
        }

        tr{
            &.child-table{
                & td{
                    padding:0.725em 1em;
                }
                &.hidden{
                    display: none;
                }
            }

            // 마우스 오버 라인 표시
            &.table-title:hover, &.child-table-body:hover td:nth-child(n+2){
                background-color: var(--bs-light);
            }

            // 자산 테이블 헤더 디자인
            &.child-table-header td:nth-child(n+2){
                background-color: var(--bs-light);
            }

            // 자산테이블 첫번째 컬럼 줄 삭제
            &.child-table td:nth-child(1){
                border: none;
            }

            // 자산 테이블 라인 디자인
            &.child-table td{
                border-top: 1px dotted var(--bs-gray-300);
            }
        }
    }
}

.TabMenu{
    display: flex;

    .TabMenuItem{
        cursor: pointer;
        font-weight: bold;
        margin-right: 1em;

        &:hover{
            text-shadow: 0px 0px 1px var(--bs-gray-600);
        }
    }

}

