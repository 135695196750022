
@keyframes vibrationFrame {
    from { transform: rotate(5deg) translateX(-1px); }
    to { transform: rotate(-5deg) translateX(1px); }
}

.vibration {
    animation: vibrationFrame 0.07s ease-in-out infinite Alternate;
}

@keyframes animationBlink {
    100%{ visibility: hidden; }
}

.blink {
    animation: animationBlink 1s steps(5, start) infinite;
}

.bell{
    // display:inline-flex;
    & > .dot{
        margin: -1.1em 0 -0.4em 0;
        color: var(--bs-primary);
        font-size: 0.835em;
    }
    // &:before{
    //     position: absolute;
    //     content: '●';
    //     font-size: 0.835em;
    //     transform: translateY(-1.2em) translateX(0.5em);
    //     color: var(--bs-primary);
    // }
}

.notification{
    --type-icon-size: 38px;
    --type-width-size: 60px;
    &.layout{
        display:flex;
        &:last-child > .type > .verticalBar{
            border:none;
        }
        & > .type{
            width: var(--type-width-size);
            & > .circle{
                text-align: center;
                font-size: 1.8em;
                width: var(--type-icon-size);
                height: var(--type-icon-size);
                border: 1px dashed var(--bs-gray-400);
                border-radius: 50% !important;
                padding-top: 0.14em;
                color: var(--bs-gray-700);
            }
            & > .verticalBar{
                border-left: 1px dashed var(--bs-gray-400);
                height: calc(100% - var(--type-icon-size));
                width: 10px;
                margin-left: calc(var(--type-icon-size) / 2);
            }
        }
        & > .body{
            display: flex;
            width: calc(100% - var(--type-width-size));
            padding-inline: 0.735em;
            & > div:nth-child(1){ width: 43%; }
            & > div:nth-child(2){ width: 15%; text-align: center;}
            & > div:nth-child(3){ width: 15%; text-align: center;}
            & > div:nth-child(4){ width: 7%; text-align: center;}
            & > div:nth-child(5){ width: 15%; text-align: center;}
            & > div:nth-child(6){ width: 5%; text-align: center;}

            &.data{
                border: 1px solid var(--bs-gray-400);
                padding: 0.75em 1.5em;
                border-radius: 0.475rem;
                margin-bottom: 2em;
                & > div {
                    align-content: center;
                }

                /* 읽지 않은 메세지 마킹 추가*/
                &[data-status='false']{
                    &::before{
                        content: '';
                        height: calc(100% + 1.1em);
                        width: 0.4em;
                        // position: absolute;
                        margin: -0.55em 0.9em -0.55em -1.3em;
                        background-color: var(--bs-primary-active);
                        border-radius: 0.5em;
                    }
                    & > div:nth-child(4){
                        color: var(--bs-primary-active);
                    }
                }

                & > div:nth-child(1){
                    & > div:nth-child(1){ 
                        font-weight: bold;
                        margin-bottom: 0.55em;
                    }
                    & > div:nth-child(2){
                        color: var(--bs-gray-700); 
                    }
                }
            }
        }
    }
}

.notification{
    &.header{
        font-weight: bold;
        color: var(--bs-gray-600);
        padding: 0.75em;
        margin: 0.75em 0;
    }
}

.badge{
    padding:0.27em 0.735em; 
    border-radius: 0.35em;
    font-size: 0.9em;
    font-weight: bold;
}


.notificationSettting{
    & > div:nth-child(n+2){
        border-top: 1px dotted var(--bs-gray-300);
    }
    /* 항목 */
    & > div{
        padding: 0.75em 0;
        /* 왼쪽 항목 설명 */
        & > div:nth-child(1){
            width: 500px;
            padding-right: 2dvh;
            & > div:nth-child(1){
                font-weight: bold;
                padding-bottom: 0.5em;
            }
            & > div:nth-child(2){
                color: var(--bs-gray-700);
            }
        }

        /* 왼쪽 항목 설명 */
        & > div:nth-child(2){
            & > div:nth-child(1){
                font-weight: bold;
                padding-bottom: 0.5em;
            }
            & > div:nth-child(n+2){
                padding-left: 2.5em;
                display: flex;
            }

        }
    }
}