
.warning_label{
    font-size: 0.9em;
    color:var(--bs-danger);
    &:hover{
        color: var(--bs-danger-active);
    }
    &  i::before{
        color:var(--bs-danger);
    }
}
.warning_icon{
    color:var(--bs-danger);
    margin-right: 0.35em;
    &:hover{
        color: var(--bs-danger-active);
    }
}

.row{
    .warning_label{
        display:none;
    }
}

.option_disabled{
    pointer-events: none;
    opacity: 0.5;
    input,textarea{
        background-color: #e2e8ee;
    }
    
}

.form_control{
    & input[type=number]{
        text-align:right;
    }
    & label{
        padding:0.75em 0 0 0.5rem;
        margin:0;
    }
}

.notice{
    font-size:0.9em;
    p{
        padding: 0;
        margin: 0;
    }
}

.badge {
    border-radius: 0.475rem;
    color: #fff;
    display: inline-block;
    font-size: .85rem;
    font-weight: 600;
    line-height: 1;
    padding: 0.5em 0.85em;
    text-align: center;
    vertical-align: baseline;
    white-space: nowrap;
}

.badge_light {
    background-color: #f5f8fa;
    color: #7e8299;
}
