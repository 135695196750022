.nalo{
    font-size: 2em;
    font-weight: bold;
    background: linear-gradient(to right, #1ad62a, #80c4e3, #9b39e1);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    &:hover{
        background: linear-gradient(to right, #049e11, #6fb9dc, #6e11b1);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
}